import {mapActions, mapGetters, mapMutations} from "vuex";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {validationMixin} from "vuelidate";
import {email, maxLength, minLength, required} from "vuelidate/lib/validators";

//sections

export default {
  name: "order",
  mixins: [validationMixin],
  components:{

  },
  data(){
    return{
      payload: {
        last_name: '',
        name: '',
        email: '',
        phone: '',
        city: '',
        state: '',
        zip: '',
      },
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      last_name: {
        required,
        maxLength: maxLength(25),
        minLength: minLength(2)
      },
      name: {
        required,
        maxLength: maxLength(25),
        minLength: minLength(2),
      },
      phone: {
        required,
        maxLength: maxLength(16),
        minLength: minLength(7),
      },
      email: {
        email,
        maxLength: maxLength(35),
        required,
      },
      city: {
        required,
        maxLength: maxLength(35),
        minLength: minLength(3),
      },
      state: {
        required,
        maxLength: maxLength(35),
        minLength: minLength(3),
      },
      zip: {
        required,
        maxLength: maxLength(10),
        minLength: minLength(5),
      },
    },
  },
  created() {
    this.initCart()
  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  computed:{
    ...mapGetters({
      cart: 'card/cart',
      cartLoading: 'card/cartLoading'
    }),
    nameError() {
      const error = []
      if (!this.$v.payload.name.$dirty) {
        return error
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 25))
      }
      if (!this.$v.payload.name.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 2))
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    lastNameError() {
      const error = []
      if (!this.$v.payload.last_name.$dirty) {
        return error
      }
      if (!this.$v.payload.last_name.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.last_name.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 25))
      }
      if (!this.$v.payload.last_name.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 2))
      }
      if (this.validationErrors.last_name) {
        this.validationErrors.last_name.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    phoneError() {
      const error = []
      if (!this.$v.payload.phone.$dirty) {
        return error
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.phone.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 16))
      }
      if (!this.$v.payload.phone.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 7))
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    emailError() {
      const error = []
      if (!this.$v.payload.email.$dirty) {
        return error
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('errorEmail'))
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 35))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    cityError() {
      const error = []
      if (!this.$v.payload.city.$dirty) {
        return error
      }
      if (!this.$v.payload.city.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.city.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 35))
      }
      if (!this.$v.payload.city.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 3))
      }
      if (this.validationErrors.city) {
        this.validationErrors.city.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    stateError() {
      const error = []
      if (!this.$v.payload.state.$dirty) {
        return error
      }
      if (!this.$v.payload.state.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.state.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 35))
      }
      if (!this.$v.payload.state.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 3))
      }
      if (this.validationErrors.state) {
        this.validationErrors.state.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    zipError() {
      const error = []
      if (!this.$v.payload.zip.$dirty) {
        return error
      }
      if (!this.$v.payload.zip.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.zip.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 10))
      }
      if (!this.$v.payload.zip.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 5))
      }
      if (this.validationErrors.zip) {
        this.validationErrors.zip.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
  },

  methods:{
    ...mapActions({
      initCart: 'card/GET_CART',
      sendOrder: 'card/ADD_ORDER'
    }),
    resetForm(){
      this.payload.name = ''
      this.payload.last_name = ''
      this.payload.email = ''
      this.payload.phone = ''
      this.payload.city = ''
      this.payload.state = ''
      this.payload.zip = ''
      this.$v.$reset()
    },
    submitForm() {
      this.$v.$touch();
      if (!this.$v.payload.$invalid) {
        this.sendOrder(this.payload).then(() => {
          this.$toasted.success('Success')
          this.resetForm()
        }).catch(error => {
          if (error.status === 422) {
            this.$toasted.error(error.data.message);
            const errors = error.data.errors;
            for(const i in errors){
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
        })
      }
    }
  }
}